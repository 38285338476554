import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import useDebounce from "app/utils/debounce";
import { getFlag } from "app/utils/languages";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export default function Thead(props) {
  const sorts = ["asc", "desc"];
  const { headers, setOrder, setFilter, orderBy } = props;

  // STATE
  const [searchTerm, setSearchTerm] = useState({});

  // DEBOUNCE
  const debouncedSearchTerm = useDebounce(searchTerm?.value, 500);

  useEffect(() => {
    if (debouncedSearchTerm) onSearch(searchTerm?.slug, debouncedSearchTerm);
    else setFilter(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  // DEFINE ONCLICK FUNCTION
  const onClick = (slug) => {
    if (!orderBy || !orderBy[slug] || orderBy[slug] === sorts[1]) setOrder({ [slug]: sorts[0] });
    else setOrder({ [slug]: sorts[1] });
  };

  // DEFINE ONCLICK FUNCTION
  const onSearch = (slug, value) => {
    setFilter({ OR: [{ [slug]: { contains: value, mode: "insensitive" } }, { slug: { contains: value, mode: "insensitive" } }] });
  };

  // DEFINE ICON
  const Icon = ({ slug, className }) => {
    if (!orderBy || !orderBy[slug]) return null;
    if (orderBy[slug] === sorts[0]) return <ChevronDownIcon className={className} />;
    else return <ChevronUpIcon className={className} />;
  };

  return (
    <thead className="relative text-xs text-gray-700 before:bg-gray-200 before:absolute before:inset-0 before:bottom-[10px] before:rounded-lg after:leading-[10px] after:-indent-96 after:block after:content-['']">
      <tr className="relative">
        {headers.map((item, index) => (
          <th scope="col" key={String(index)} className={`py-6 px-6 ${item.isLang ? "whitespace-nowrap" : ""}`}>
            {
              // WITH SEARCH
              item.search ? (
                <div className="inline-flex items-center group">
                  <MagnifyingGlassIcon className="h-4 mr-1 -translate-x-4 opacity-0 transition-all duration-500 ease-linear group-hover:translate-x-0 group-hover:opacity-100 group-focus-within:translate-x-0 group-focus-within:opacity-100" />
                  <input
                    type="text"
                    placeholder={item.name}
                    className="p-0 py-1 text-sm outline-none border-none shadow-none ring-0 bg-transparent border-b focus:border-b-gray-400 focus:border-b focus:ring-0 -translate-x-5 transition-all duration-500 ease-linear group-hover:translate-x-0 group-focus-within:translate-x-0 group-focus-within:opacity-100"
                    onChange={(e) => {
                      setSearchTerm({ slug: item.slug, value: e.target.value });
                    }}
                  />
                </div>
              ) : // WITH SORT
              !item.noSort ? (
                <button type="button" className="text-sm hover:opacity-80 transition-all duration-300 ease-in-out" onClick={() => onClick(item.slug)}>
                  {item.name} <Icon slug={item.slug} className="inline w-4" />
                </button>
              ) : // BASIC
              item.isLang && item.options?.length ? (
                <span>
                  {item.options.map((lang, key) => (
                    <span key={key} className="uppercase text-xl h-4 w-6 mx-1 inline-flex items-center justify-center">
                      {getFlag(lang)}
                    </span>
                  ))}
                </span>
              ) : (
                // DEFAULT
                <span>{item.name}</span>
              )
            }
          </th>
        ))}
        <th />
      </tr>
    </thead>
  );
}

Thead.propTypes = {
  headers: PropTypes.array.isRequired,
  setOrder: PropTypes.func,
  orderBy: PropTypes.object,
};
