import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

export default function Tfoot(props) {
  const { cols, setPage, total, current, pages } = props;
  const [forcePage, setForcePage] = useState(current - 1);

  useEffect(() => {
    setForcePage(current - 1);
  }, [current]);

  if (!total) return null;

  const handlePageClick = ({ selected }) => {
    setPage(selected + 1);
  };

  return (
    <tfoot className="relative table-footer-pagination">
      <tr className="">
        <td colSpan={cols} className="relative pt-5 h-20 align-top">
          <div className="flex justify-center">
            <ReactPaginate
              nextLabel=">"
              breakLabel="..."
              previousLabel="<"
              pageCount={pages}
              pageRangeDisplayed={3}
              forcePage={forcePage}
              renderOnZeroPageCount={true}
              onPageChange={handlePageClick}
            />
          </div>
        </td>
      </tr>
    </tfoot>
  );
}
