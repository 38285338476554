import { Dialog, Transition } from "@headlessui/react";
import Icon from "app/components/atoms/icons/icon";
import Toggle from "app/components/atoms/inputs/toggle";
import Form from "app/components/organisms/form";
import { validation } from "app/utils/validators";
import { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Loading from "app/components/atoms/loading";
import { i18n } from "app/i18n";

export default function ModalConfirm(props) {
  const {
    // FUNCTIONS
    onConfirm,
    onClose = () => {},

    // VARS
    type = "CONFIRM", // ["CONFIRM", "DELETE", "EMAIL", "SAVE"]
    mode = "BASIC", // ["BASIC", "REQUIRED", "INPUTS"]
    isOpen = false,
    isLoading = false,
    forceNotice = false,
    inputs = [],

    // TEXTS
    title = i18n("label.delete"),
    buttonText = i18n("label.confirm"),
    text = i18n("alert.action_delete"),

    buttonCancel = i18n("button.cancel"),
    notice = i18n("alert.accept_before_submit"),

    // INPUTS
    defaultValues,
  } = props;

  const cancelButtonRef = useRef(null);

  // REQUIRED
  const [accepted, setAccepted] = useState(false);
  const [showNotice, setShowNotice] = useState(false);

  // INPUTS
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(null);

  // ICON
  const [icon, setIcon] = useState({ name: "delete", color: "text-red-600", bg: "bg-red-100", button: "red" });

  useEffect(() => {
    if (isOpen) {
      if (mode === "INPUTS") setStructure(inputs);
      if (type) defineIcon();
      if (forceNotice) setShowNotice(true);
      if (defaultValues) setValue(defaultValues);
    } else {
      setValue(null);
      setStructure(null);
      setAccepted(false);
      setShowNotice(false);
    }
  }, [isOpen, mode, type, forceNotice]);

  const defineIcon = () => {
    if (type === "DELETE") setIcon({ name: "delete", color: "text-red-600", bg: "bg-red-100", button: "red" });
    else if (type === "SAVE") setIcon({ name: "logout", color: "text-blue-600", bg: "bg-blue-100", button: "blue" });
    else if (type === "EMAIL") setIcon({ name: "email", color: "text-blue-600", bg: "bg-blue-100", button: "blue" });
    else setIcon({ name: "check", color: "text-blue-600", bg: "bg-blue-100", button: "blue" });
  };

  const onClickConfirm = () => {
    if (mode === "REQUIRED") {
      if (accepted) onConfirm(true);
      else setShowNotice(true);
    } else if (mode === "BASIC") {
      onConfirm();
    } else if (mode === "INPUTS") {
      validation(structure, value, (structure, validation) => {
        setStructure([...structure]);
        if (validation.isValid) onConfirm(value);
      });
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${icon.bg} sm:mx-0 sm:h-10 sm:w-10`}>
                      <Icon name={icon.name} className={icon.color} />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                      {text && (
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{text}</p>
                        </div>
                      )}
                      {mode === "REQUIRED" && (
                        <div className="mt-6">
                          <Toggle title={i18n("label.confirm_decision")} value={accepted} texts={[i18n("label.accept"), i18n("label.not_accept")]} onChange={setAccepted} />
                        </div>
                      )}
                      {mode === "INPUTS" && (
                        <div className="mt-6">
                          <Form value={value} onChange={setValue} structure={structure} />
                        </div>
                      )}
                      {showNotice && (
                        <div className="flex mt-4 items-center rounded-md border border-transparent bg-orange-100 text-gray-600 h-full py-2 pl-3 pr-6">
                          <div className="h-8 w-8 flex-shrink-0 rounded-full items-center justify-center bg-orange-200 text-orange-600 flex mr-2">
                            <Icon name="alert" />
                          </div>
                          <span className="text-xs inline-block leading-tight">{notice}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:items-center sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={onClickConfirm}
                    className={`inline-flex w-full justify-center rounded-md border border-transparent bg-${icon.button}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${icon.button}-800 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm transition-all duration-500 ease-in-out`}
                  >
                    {buttonText}
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    ref={cancelButtonRef}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm transition-all duration-500 ease-in-out"
                  >
                    {buttonCancel}
                  </button>
                  <Loading active={isLoading} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ModalConfirm.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(["CONFIRM", "DELETE", "EMAIL", "SAVE"]),
  mode: PropTypes.oneOf(["BASIC", "REQUIRED", "INPUTS"]),
  isOpen: PropTypes.bool,
  forceNotice: PropTypes.bool,
  inputs: PropTypes.array,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  text: PropTypes.string,
  buttonCancel: PropTypes.string,
  notice: PropTypes.string,
};
