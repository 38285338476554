import { useState, useEffect } from "react";

/**
 * Hook personalizado para debouncing de valores.
 * @param {any} value - O valor que será debounced.
 * @param {number} delay - O atraso em milissegundos para o debouncing.
 * @returns {any} - O valor debounced.
 */
export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Cria um timer que atualiza o valor debounced após o atraso especificado
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Limpa o timer se o valor ou o atraso mudarem, ou quando o componente é desmontado
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
